import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Header from "../Header/index";

import LogoLight from "../../assets/images/logo-light.png";
import LogoDark from "../../assets/images/logo-dark.png";
import logoSmall from "../../assets/images/Logo.jpg";

const Sidebar = () => {
  const [sideBarState, setSideBarState] = useState(false);
  const [adminRole, setAdminRole] = useState(null);
  const [adminInfo, setAdminInfo] = useState(null);

  useEffect(() => {
    const AdminData = JSON.parse(secureLocalStorage.getItem("adminInfo"));

    setAdminRole(AdminData[0]?.role[0]?.title);

    setAdminInfo(AdminData[0]?.admin_permissions);
  }, []);

  const handleChange = () => {
    setSideBarState(!sideBarState);
  };

  useEffect(() => {
    const localStatus = JSON.parse(secureLocalStorage.getItem("statusSidebar"));

    setSideBarState(localStatus);
  }, []);

  useEffect(() => {
    if (sideBarState === true) {
      document.body.classList.add("vertical-collpsed");
    } else {
      document.body.classList.remove("vertical-collpsed");
    }
    secureLocalStorage.setItem("statusSidebar", JSON.stringify(sideBarState));
  }, [sideBarState]);

  const location = useLocation();

  return (
    <>
      <Header handleChange={handleChange} />
      <div className="vertical-menu">
        {/* LOGO */}
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSmall} alt="" height={35} />
            </span>
            <span className="logo-lg">
              <img src={LogoDark} alt="" height={35} />
            </span>
          </Link>
          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoSmall} alt="" height={30} />
            </span>
            <span className="logo-lg">
              <img src={LogoLight} alt="" height={35} />
            </span>
          </Link>
        </div>
        {/* <button
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
          onClick={handleChange}
        >
          <i className="fa fa-fw fa-bars" />
        </button> */}
        <div data-simplebar="init" className="sidebar-menu-scroll">
          <div className="simplebar-wrapper" style={{ margin: 0 }}>
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer" />
            </div>
            <div className="simplebar-mask">
              <div
                className="simplebar-offset"
                style={{ right: "-17px", bottom: 0 }}
              >
                <div
                  className="simplebar-content-wrapper"
                  style={{ height: "100%", overflow: "hidden scroll" }}
                >
                  <div className="simplebar-content" style={{ padding: 0 }}>
                    {/*- Sidemenu */}
                    <div id="sidebar-menu" className="mm-active">
                      {/* Left Menu Start */}
                      <ul
                        className="metismenu list-unstyled mm-show"
                        id="side-menu"
                      >
                        <li className="menu-title">Menu</li>
                        <li
                          className={
                            location.pathname === "/dashboard"
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/dashboard">
                            <i className="uil-home-alt" />
                            <span>Dashboard</span>
                          </Link>
                        </li>
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-products"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/products"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/products">
                              <i className="uil-store" />
                              <span>Products</span>
                            </Link>
                          </li>
                        ) : null}
                        {/* {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-attribute"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/attributes"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/attributes">
                              <i className="uil-store" />
                              <span>Attributes</span>
                            </Link>
                          </li>
                        ) : null} */}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-categories"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/categories"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/categories">
                              <i className="uil-store" />
                              <span>Categories</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-brands"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/brands" ? "mm-active" : ""
                            }
                          >
                            <Link to="/brands">
                              <i className="uil-store" />
                              <span>Brands</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-orders"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/orders" ? "mm-active" : ""
                            }
                          >
                            <Link to="/orders">
                              <i className="uil-store" />
                              <span>Orders</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-customers"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/customers"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/customers">
                              <i className="uil-store" />
                              <span>Customers</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-coupons"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/coupons"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/coupons">
                              <i className="uil-store" />
                              <span>Coupons</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-blogs"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/blogs" ? "mm-active" : ""
                            }
                          >
                            <Link to="/blogs">
                              <i className="uil-store" />
                              <span>Blogs</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-find-for-me"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/find-for-me"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/find-for-me">
                              <i className="uil-store" />
                              <span>Find it for me</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-find-for-me"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/contact-form"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/contact-form">
                              <i className="uil-store" />
                              <span>Contact Queries</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-banners"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/banner" ? "mm-active" : ""
                            }
                          >
                            <Link to="/banner">
                              <i className="uil-store" />
                              <span>Banners</span>
                            </Link>
                          </li>
                        ) : null}
                        {/* {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-currency"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/currency"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/currency">
                              <i className="uil-store" />
                              <span>Currency</span>
                            </Link>
                          </li>
                        ) : null} */}
                        {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "create-deal-of-the-month"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/deal_of_month"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/deal_of_month">
                              <i className="uil-user-circle" />
                              <span>Deal of the month</span>
                            </Link>
                          </li>
                        ) : null}
                        {/* {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-products"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/employes"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/employes">
                              <i className="uil-user-circle" />
                              <span>Employees</span>
                            </Link>
                          </li>
                        ) : null} */}
                        {/* {adminInfo?.some(
                          (permissionObj) =>
                            permissionObj?.title === "read-products"
                        ) ? (
                          <li
                            className={
                              location.pathname === "/role" ? "mm-active" : ""
                            }
                          >
                            <Link to="/role">
                              <i className="uil-user-circle" />
                              <span>Role Management</span>
                            </Link>
                          </li>
                        ) : null} */}
                        {adminRole === "Admin" ? (
                          <li
                            className={
                              location.pathname === "/settings"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/settings">
                              <i className="uil-user-circle" />
                              <span>Settings</span>
                            </Link>
                          </li>
                        ) : null}
                        {adminRole === "Admin" ? (
                          <li
                            className={
                              location.pathname === "/content/pages"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <Link to="/content/pages">
                              <i className="uil-user-circle" />
                              <span>Content Pages</span>
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                    {/* Sidebar */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="simplebar-placeholder"
              style={{ width: "auto", height: 1668 }}
            />
          </div>
          <div
            className="simplebar-track simplebar-horizontal"
            style={{ visibility: "hidden" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                display: "none",
              }}
            />
          </div>
          <div
            className="simplebar-track simplebar-vertical"
            style={{ visibility: "visible" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{
                height: 41,
                transform: "translate3d(0px, 150px, 0px)",
                display: "block",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
