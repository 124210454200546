import React, { lazy, Suspense } from "react";
import PrivateComponent from "./Private/PrivateComponent";
import { Route, Routes } from "react-router-dom";

import Loader from "./Reuseable/Loader";

const AddBlogs = lazy(() => import("./Pages/Blogs/AddBlogs"));
const EditBlogs = lazy(() => import("./Pages/Blogs/EditBlogs"));
const Banners = lazy(() => import("./Pages/Banners/Banners"));
const Currency = lazy(() => import("./Pages/Currency/Currency"));
const EditBanner = lazy(() => import("./Pages/Banners/EditBanner"));
const Staff = lazy(() => import("./Pages/Staff/Staff"));
const Roles = lazy(() => import("./Pages/Staff/Roles"));
const AddRoles = lazy(() => import("./Pages/Staff/AddRoles"));
const AddStaff = lazy(() => import("./Pages/Staff/AddStaff"));
const ContentPages = lazy(() => import("./Pages/ContentPages/ContentPages"));
const EditContent = lazy(() => import("./Pages/ContentPages/editContent"));
const EditProducts = lazy(() => import("./Pages/Products/EditProducts"));
const Login = lazy(() => import("./Auth/Login"));
const Contact = lazy(() => import("./Pages/ContactQuery"));
const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
const ProductList = lazy(() => import("./Pages/Products/ProductList"));
const Attributes = lazy(() => import("./Pages/Attributes/Attributes"));
const Categories = lazy(() => import("./Pages/Categories/Categories"));
const Brands = lazy(() => import("./Pages/Brands/Brands"));
const Customers = lazy(() => import("./Pages/Customers/Customers"));
const Orders = lazy(() => import("./Pages/Orders/Orders"));
const Coupons = lazy(() => import("./Pages/Coupons/Coupons"));
const Blogs = lazy(() => import("./Pages/Blogs/Blogs"));
const FindForMe = lazy(() => import("./Pages/FindForME/FindForMe"));
const AddProducts = lazy(() => import("./Pages/Products/AddProducts"));
const Settings = lazy(() => import("./Pages/Settings/Settings"));
const DealOfMonth = lazy(() => import("./Pages/DealOfMonth/DealOfMonth"));

const App = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />
        <Route element={<PrivateComponent />}>
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            }
          />
          {/* Products Route Start */}
          <Route
            path="/products"
            element={
              <Suspense fallback={<Loader />}>
                <ProductList />
              </Suspense>
            }
          />
          <Route
            path="/products-edit/:id"
            element={
              <Suspense fallback={<Loader />}>
                <EditProducts />
              </Suspense>
            }
          />
          <Route
            path="/products-add"
            element={
              <Suspense fallback={<Loader />}>
                <AddProducts />
              </Suspense>
            }
          />
          {/* Products Route End */}
          <Route
            path="/attributes"
            element={
              <Suspense fallback={<Loader />}>
                <Attributes />
              </Suspense>
            }
          />
          <Route
            path="/categories"
            element={
              <Suspense fallback={<Loader />}>
                <Categories />
              </Suspense>
            }
          />
          <Route
            path="/brands"
            element={
              <Suspense fallback={<Loader />}>
                <Brands />
              </Suspense>
            }
          />
          <Route
            path="/orders"
            element={
              <Suspense fallback={<Loader />}>
                <Orders />
              </Suspense>
            }
          />
          <Route
            path="/customers"
            element={
              <Suspense fallback={<Loader />}>
                <Customers />
              </Suspense>
            }
          />
          <Route
            path="/blogs"
            element={
              <Suspense fallback={<Loader />}>
                <Blogs />
              </Suspense>
            }
          />
          <Route
            path="/blog-add"
            element={
              <Suspense fallback={<Loader />}>
                <AddBlogs />
              </Suspense>
            }
          />
          <Route
            path="/blog-edit/:id"
            element={
              <Suspense fallback={<Loader />}>
                <EditBlogs />
              </Suspense>
            }
          />
          <Route
            path="/coupons"
            element={
              <Suspense fallback={<Loader />}>
                <Coupons />
              </Suspense>
            }
          />
          <Route
            path="/find-for-me"
            element={
              <Suspense fallback={<Loader />}>
                <FindForMe />
              </Suspense>
            }
          />
          <Route
            path="/contact-form"
            element={
              <Suspense fallback={<Loader />}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="/banner"
            element={
              <Suspense fallback={<Loader />}>
                <Banners />
              </Suspense>
            }
          />
          <Route
            path="/edit-banner/:id"
            element={
              <Suspense fallback={<Loader />}>
                <EditBanner />
              </Suspense>
            }
          />
          <Route
            path="/currency"
            element={
              <Suspense fallback={<Loader />}>
                <Currency />
              </Suspense>
            }
          />
          <Route
            path="/employes"
            element={
              <Suspense fallback={<Loader />}>
                <Staff />
              </Suspense>
            }
          />
          <Route
            path="/employes-add"
            element={
              <Suspense fallback={<Loader />}>
                <AddStaff />
              </Suspense>
            }
          />
          <Route
            path="/role"
            element={
              <Suspense fallback={<Loader />}>
                <Roles />
              </Suspense>
            }
          />
          <Route
            path="/roles-add"
            element={
              <Suspense fallback={<Loader />}>
                <AddRoles />
              </Suspense>
            }
          />
          <Route
            path="/settings"
            element={
              <Suspense fallback={<Loader />}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path="/deal_of_month"
            element={
              <Suspense fallback={<Loader />}>
                <DealOfMonth />
              </Suspense>
            }
          />
          <Route
            path="/content/pages"
            element={
              <Suspense fallback={<Loader />}>
                <ContentPages />
              </Suspense>
            }
          />
          <Route
            path="/edit/content/pages/:pageName"
            element={
              <Suspense fallback={<Loader />}>
                <EditContent />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
